/* eslint-disable import/no-anonymous-default-export */
import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "1E212E";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#4D0000",
    main: "#FF6464",
    light: "#B20000",
  },
  secondary: {
    contrastText: white,
    dark: "#FFCC66",
    main: "#FFF5E1",
    light: "#FCEDCF",
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: "#1E212E",
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: "#F5F5DC",
    paper: white,
  },
  divider: colors.grey[200],
};
