import "../App.css";
function Footer() {
  return (
    <footer>
      Made by <a href="www.mines-dev.com">Mines-dev</a> 2023 ©
    </footer>
  );
}

export default Footer;
