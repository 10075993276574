import { Box, Link, Stack, Typography } from "@mui/material";
import Form from "../Components/Form";

import { Facebook, Instagram, Twitter } from "@mui/icons-material";

function FormScreen() {
  return (
    <>
      <Typography
        sx={{ mt: "4em" }}
        variant="subtitle1"
        textAlign={"center"}
        gutterBottom>
        Get your relax tretman in order, treat your hair with style
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing="2em"
        sx={{
          justifyContent: "space-around",
          p: "2em",
          mt: "2em",
        }}>
        <div className="levo">
          <Form />
        </div>
        <Stack className="desno">
          <Box variant="body1">
            <Typography sx={{ mt: 2 }}>
              Work schedule: from 8:00 to 20:00
            </Typography>
            <Typography sx={{ textAlign: { xs: "left", md: "right" }, mt: 2 }}>
              Monday - Friday
            </Typography>
            <Typography sx={{ mt: 2 }}>On weekend we don't work</Typography>
          </Box>

          <Stack direction={"row"} sx={{ mt: 4 }}>
            Social networks:
            <Box sx={{ ml: 2 }}>
              <Link href="/#">{<Facebook />}</Link>{" "}
            </Box>
            <Box sx={{ ml: 2 }}>
              <Link href="/#">{<Instagram />}</Link>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Link href="/#">{<Twitter />}</Link>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default FormScreen;
