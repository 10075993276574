import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function Faq() {
  return (
    <Box sx={{ width: "90%", m: "1em auto" }}>
      <Typography variant="h2" sx={{ mb: 4 }}>
        Freqently asked questions
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="question-1"
          id="question-1">
          <Typography>
            Do I need to make an appointment or can I walk in?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We recommend making an appointment to ensure we can provide you with
            the best service and accommodate your schedule effectively. However,
            walk-ins are also welcome, and we will do our best to accommodate
            you if we have availability.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="question-2"
          id="question-2">
          <Typography>
            Do you have any special promotions or discounts?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we offer special promotions and discounts to our valued
            customers. Be sure to check our social media profiles, or inquire
            with our staff to stay updated on our current offers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="question-3"
          id="question-3">
          <Typography>
            Do you have a referral program for new clients?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we have an exciting referral program for our valued clients.
            When you refer a new client to our salon, both you and the referred
            client can enjoy special discounts or rewards on select services or
            products. It's our way of showing appreciation for your loyalty and
            support.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="question-4"
          id="question-4">
          <Typography>Do you accept credit cards or cash only?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We accept both credit cards and cash as payment methods for our
            services and products, providing you with convenient payment options
            to suit your preference.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="question-5"
          id="question-5">
          <Typography>Is there parking available nearby?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we have our private parking that is available for our customers
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
