import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <section className="backgroundImage">
      <Stack>
        <Typography variant="h1" className="hero-title" gutterBottom>
          Get a new hairstyle, don't get just a haircut.
        </Typography>
        <Typography variant="subtitle1">
          Elevate Your Look with Expert Styling
        </Typography>
        <Link className="cta" to={"/create appointment"}>
          Create appointment
        </Link>
      </Stack>
    </section>
  );
}

export default Hero;
