import { Avatar, Stack, Typography } from "@mui/material";
import "../App.css";

function Testelmonial({ testimonialName, testimonialComment }) {
  function stringAvatar(testimonialName) {
    return {
      sx: {
        width: 60,
        height: 60,
      },
      children: `${testimonialName.split(" ")[0][0]}${
        testimonialName.split(" ")[1][0]
      }`,
    };
  }
  return (
    <Stack
      sx={{ flexDirection: { sm: "column", md: "row" } }}
      justifyContent={"space-around"}
      spacing={2}
      m={2}>
      <Stack direction="column" className="avatar-name estelmonial-avatar">
        <Avatar {...stringAvatar(testimonialName)} className="avatar-pic" />
        <Typography variant="p">{testimonialName}</Typography>
      </Stack>
      <div className="testelmonial-text">
        <Typography variant="body1" className="testelmonial-inner-text">
          {testimonialComment}
        </Typography>
      </div>
    </Stack>
  );
}

export default Testelmonial;
