import Hero from "../Components/Hero";
import TestimonialsScreen from "./TestimonialsScreen";
import About from "../Components/About";
import FormScreen from "./FormScreen";
import Faq from "../Components/Faq";

function HomeScreen() {
  return (
    <>
      <Hero />
      <About />
      <FormScreen />
      <TestimonialsScreen />
      <Faq />
    </>
  );
}

export default HomeScreen;
