import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFoundScreen() {

  const navigate = useNavigate();
  
  useEffect(() => {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 3000);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}>
      <Typography variant="h1" sx={{ fontSize: "2rem", fontWeight: "bold" }}>
        404 page
      </Typography>
      <Typography variant="subtitle1">this page does not exist</Typography>
    </Box>
  );
}

export default NotFoundScreen;
