import { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TestemonialForm from "../Components/TestemonialForm";
import Testimonial from "../Components/Testelmonial";
function TestimonialsScreen() {
  const [testimonials, setTestimonials] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let isCleaned = false;
    async function getTestimonials() {
      const response = await fetch(`http://localhost:5000/testimonials/`);

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }
      if (!isCleaned) {
        const testimonials = await response.json();
        setTestimonials(testimonials);
      }
    }
    getTestimonials();
    return () => {
      isCleaned = true;
    };
  }, [testimonials.length]);

  async function deleteTestimonial(id) {
    await fetch(`http://localhost:5000/testimonials/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const newTestimonials = testimonials.filter((el) => el._id !== id);
    setTestimonials(newTestimonials);
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function stringAvatar(name) {
    return {
      sx: {
        width: 50,
        height: 50,
      },
      children: `${name.split(" ")[0][0].toUpperCase()}${name
        .split(" ")[1][0]
        .toUpperCase()}`,
    };
  }
  return (
    <section style={{ width: "90%", margin: "4em auto" }}>
      <Stack
        sx={{
          flexDirection: { sm: "column", md: "row" },
          width: "90%",
          m: "1em auto",
        }}
        justifyContent={"space-between"}
        gap={"2em"}
        alignItems={"center"}>
        <Typography variant="h2">What our customer's say</Typography>
        <div style={{ marginTop: 0 }}>
          <Button
            sx={{ width: "fit-content" }}
            variant="contained"
            onClick={handleOpen}>
            Create a new testelmonial
          </Button>
        </div>
      </Stack>

      <Testimonial
        testimonialName={"Verica Ruzic"}
        testimonialComment={
          "I had an amazing experience at this hair salon! The stylists are incredibly talented, and I walked out feeling like a million bucks with a fabulous new hairstyle."
        }
      />
      <Testimonial
        testimonialName={"Natalee Warner"}
        testimonialComment={
          "This hair salon exceeded my expectations in every way. From the moment I walked in, the staff made me feel welcomed, and the results of my haircut and color were absolutely stunning!"
        }
      />
      <Testimonial
        testimonialName={"Laurel Flowers"}
        testimonialComment={
          "The professionalism, attention to detail, and friendly atmosphere create the perfect environment for a fantastic hair transformation. I'm thrilled with my new look!"
        }
      />
      <Stack alignItems={"flex-start"}>
        {testimonials.map((testimonial) => {
          return (
            <Stack
              key={testimonial._id}
              sx={{ flexDirection: { sm: "column", md: "row" } }}
              justifyContent={"space-around"}
              spacing={4}
              m={4}>
              <Stack
                direction="column"
                className="avatar-name estelmonial-avatar">
                <Avatar
                  sx={{ background: "#ff6967" }}
                  {...stringAvatar(testimonial.user)}
                  className="avatar-pic"
                />
                <Typography variant="body1">{testimonial.user}</Typography>
              </Stack>
              <div className="testelmonial-text">
                <div className="testelmonial-inner-text">
                  <Typography variant="body1">{testimonial.message}</Typography>
                </div>
              </div>
              <Button
                onClick={() => {
                  deleteTestimonial(testimonial._id);
                }}>
                <DeleteIcon />
              </Button>
            </Stack>
          );
        })}
      </Stack>
      <Dialog open={open} fullWidth maxWidth={"sm"} onClose={handleClose}>
        <DialogContent>
          <TestemonialForm />
        </DialogContent>
      </Dialog>
    </section>
  );
}

export default TestimonialsScreen;
